import React, { useState,useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { reset_Password } from "../../../apis/Signup"
const ResetPassword = () => {
    const LoginData = localStorage.getItem('singupToken')
    const data = JSON.parse(LoginData)
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        // reset
    } = useForm();
    useEffect(() => {
        if (LoginData) {

            setValue('email', data.email);
            setValue('password', data.password);
        }
    }, [LoginData])
    const password = useRef({});
    password.current = watch("password", "");
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null); 
    const token = useParams();
    const onSubmit = async (data) => 
        {
            data.roles ='Student';
            data.token=token?.token;
            let result = await reset_Password(data);
            if (result) {
                return navigate('/login');
            } 
        }
    return (
        <div className="row">
            <div className="col-12">
                {/* <div className="member_title">
                    <h2>Join Membership</h2> */}
                    {/* <h4>Dont have an account? <Link to="/loginFreeAssesment">Free Signup</Link></h4> */}
                    {/* <h4>Don't have an account? <Link to="/loginFreeAssesment">Signup</Link></h4>
                </div> */}
                <div className="col-12 text-end">
                    {/* <div className="member_title">
                <h4><Link to="/loginFreeAssesment">Login With Free Assesment</Link></h4>
                </div> */}
                </div>
                <div className="login_cont">
                    <div className="info_cont">
                        <h4>Forgot Password</h4>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3 info_field">
                                            <label for="exampleFormControlInput1" className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Password"
                                                {...register('password', {
                                                    required: true,
                                                    pattern: {
                                                        value: /^.{4,}$/, // At least 4 characters
                                                        message: "Password must be at least 4 characters long."
                                                    }
                                                })}
                                            />
                                            {errors.password && errors.password.type === "required" && (
                                                <span className="validation-error">This is required</span>
                                            )}
                                            {errors.password && errors.password.type === "pattern" && (
                                                <span className="validation-error">{errors.password.message}</span>
                                            )}

                                            <div className="work_icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    <div className="col-lg-6">
                                        <div className="mb-3 info_field">
                                            <label for="exampleFormControlInput1" className="form-label">Confirm Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="ConfirmPassword"
                                                {...register('confirmPassword', {
                                                    required: true,
                                                    validate: value =>
                                                        value === password.current || "The passwords do not match"
                                                })}
                                            />
                                            {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                                                <span className="validation-error">This is required</span>
                                            )}
                                             {errors.confirmPassword && <p className="validation-error">{errors.confirmPassword.message}</p>}

                                            <div className="work_icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                </div>
                                <div className="next_btn member_sub_btn">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;

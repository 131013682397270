import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner from "../../assets/Images/childrenBanner.png.png";
import studentImage from "../../assets/Images/childrenImage.png.png";
import { Get_current_year } from "../../utils/Utils";

const LoginLayouts = () => {
  const [toggle, setToggle] = useState(false);
  const [heading, setHeading] = useState("");
  const location = useLocation();
  const toggleClass = () => {
    setToggle(!toggle);
  };
  var settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // get path name
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.startsWith("/student/reset-password/")) {
      setHeading("Reset Password");
    } else {
      const formattedPath = currentPath
        .split("/")
        .filter((part) => part)
        .map((part) => part.replace("-", " "))
        .join(" ");

      setHeading(formattedPath);
    }
  }, [location]);

  return (
    <body className={`sb-nav-fixed ${toggle ? "sb-sidenav-toggled" : ""}`}>
      <div className="myheader">
        <div className="top_bar">
          <div className="top_bar_inner">
            <h4>
              <i className="fa-solid fa-phone"></i> +61 2 8883 0055{" "}
              <span className="h_line">|</span>{" "}
              <i className="fa-solid fa-location-dot"></i> Unit 121, 14
              Lexington Dr. Bella vista NSW 2153
            </h4>
          </div>
          {/* <div className="top_bar_inner">
            <h4>
              {" "}
              <span className="h_line">|</span> VIC{" "}
              <span className="h_line">|</span> QLD{" "}
              <span className="h_line">|</span> SA
            </h4>
          </div> */}
        </div>
        {/* <nav className="sb-topnav navbar navbar-expand">
          <Link className="navbar-brand ps-3" to="/">
            <img src="images/logo.png" />
          </Link> */}
          {/* <button onClick={() => toggleClass()} className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17C14 16.7348 13.8946 16.4804 13.7071 16.2929C13.5196 16.1054 13.2652 16 13 16ZM3 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8ZM21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
                                fill="black"
                            />
                        </svg>
                    </button> */}
          {/* <ul className="navbar-nav ms-auto log_tab">
            <li>
              <Link to="/loginFreeAssesment">Signup</Link>
            </li> */}
            {/* <li><Link to="/join-member">Signup</Link></li> */}
            {/* <li>
              <Link to="/login">Login</Link>
            </li>
          </ul>
        </nav> */}
      </div>
      {/* <div id="layoutSidenav"> */}
        {/* <div id="layoutSidenav_nav">
          <nav
            className="sb-sidenav accordion sb-sidenav-dark"
            id="sidenavAccordion"
          >
            <div className="sb-sidenav-menu">
              <div className="nav">
                <div className="join_memberleftcont">
                  <div className="join_head">
                    <h4>Our Latest News</h4>
                  </div>
                  <Slider {...settings}>
                    <div class="lapii_img">
                      <img
                        class="w-100"
                        src="./images/lapiigirl.png"
                        alt="lapiigirl"
                      />
                      <p>
                        CS Education 1 brings together everything needed for a
                        Total Education Solution. We aims to offer an
                        educational solution providing everything necessary to
                        equip students with an education equal to the best in
                        the world in the 21st century.
                      </p>
                    </div>
                    <div class="lapii_img">
                      <img
                        class="w-100"
                        src="./images/lapiigirl.png"
                        alt="lapiigirl"
                      />
                      <p>
                        CS Education 2 brings together everything needed for a
                        Total Education Solution. We aims to offer an
                        educational solution providing everything necessary to
                        equip students with an education equal to the best in
                        the world in the 21st century.
                      </p>
                    </div>
                    <div class="lapii_img">
                      <img
                        class="w-100"
                        src="./images/lapiigirl.png"
                        alt="lapiigirl"
                      />
                      <p>
                        CS Education 3 brings together everything needed for a
                        Total Education Solution. We aims to offer an
                        educational solution providing everything necessary to
                        equip students with an education equal to the best in
                        the world in the 21st century.
                      </p>
                    </div>
                    <div class="lapii_img">
                      <img
                        class="w-100"
                        src="./images/lapiigirl.png"
                        alt="lapiigirl"
                      />
                      <p>
                        CS Education brings together everything needed for a
                        Total Education Solution. We aims to offer an
                        educational solution providing everything necessary to
                        equip students with an education equal to the best in
                        the world in the 21st century.
                      </p>
                    </div>
                  </Slider>
                  <div className="join_head tutorial">
                    <h4>Our Tutorial Videos</h4>
                  </div>
                  <Slider {...settings}>
                    <div className="joinmen_img">
                      <img
                        className="w-100"
                        src="./images/lapiiboy.png"
                        alt="lapiiboy1"
                      />
                    </div>
                    <div className="joinmen_img">
                      <img
                        className="w-100"
                        src={studentImage}
                        alt="lapiiboy2"
                      />
                    </div>
                    <div className="joinmen_img">
                      <img
                        className="w-100"
                        src="./images/lapiiboy.png"
                        alt="lapiiboy3"
                      />
                    </div>
                    <div className="joinmen_img">
                      <img
                        className="w-100"
                        src={studentImage}
                        alt="lapiiboy4"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </nav>
        </div> */}
     
      {/* </div> */}
      <div id="layoutSidenav_content">
          <main className="new_student_footer">
            <div className="container-fluid px-md-4">
              <Outlet />
            </div>
          </main>
          <footer className="py-2 footer-bg footer-sec">
            <div className="container-fluid px-md-4">
              <div className="d-flex align-items-center justify-content-between small flex-column flex-md-row">
                <div className="text-muted">
                  © Copyright 2007-{Get_current_year()} CS Education
                </div>
                <div className="policy">
                  {/* <Link to="#">Our Branches : VIC | QLD | SA</Link> */}
                  <Link
                    to="https://connectedschool.com.au/about-us"
                    target="_blank"
                  >
                    About Us
                  </Link>
                  <Link
                    to="https://connectedschool.com.au/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  <span class="cpyrt-txt">
                    <Link to="https://connectedschool.com.au/contact-us"  target="_blank">Contact Us</Link>
                  </span>
                </div>
              </div>
            </div>
          </footer>
        </div>
    </body>
  );
};

export default LoginLayouts;

import React from "react";
import { Link } from "react-router-dom";
import { Get_current_year } from "../../utils/Utils";
export default function Footer() {
  return (
    <footer class="py-2 footer-bg">
      <div class="container-fluid px-md-4">
        <div class="d-flex align-items-center justify-content-between small flex-column flex-md-row">
          <div class="text-muted">
            © Copyright 2007-{Get_current_year()} CS Education
          </div>
          <div className="policy">
            {/* <Link to="#">Our Branches : VIC | QLD | SA</Link> */}
            <Link to="https://connectedschool.com.au/about-us" target="_blank">
              About Us
            </Link>
            <Link
              to="https://connectedschool.com.au/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              to="https://connectedschool.com.au/contact-us"
              target="_blank"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

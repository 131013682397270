import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { forgot_Password } from "../../../apis/Signup"
const ForgotPassword = () => {
    const LoginData = localStorage.getItem('singupToken')
    const data = JSON.parse(LoginData)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        // reset
    } = useForm();
    useEffect(() => {
        if (LoginData) {

            setValue('email', data.email);
            setValue('password', data.password);
        }
    }, [LoginData])
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null); 
    // const onSubmit = async (data) => {
    //    data.roles ='Teacher';
    //     data.role_id='2'
    //     const res = await forgot_Password(data);
    //     if (res?.status) {
    //         // Successful login
    //         return navigate('/dashboard');
    //     } else {
    //         // Handle the error (e.g., incorrect password)
    //         setErrorMessage(res?.message || "Invalid email or password.");
    //     }
    // };
    const onSubmit = async (data) => 
        {
            data.roles ='Student';
            data.role_id='3'
            let result = await forgot_Password(data);
        }
    return (
        <div className="row">
            <div className="col-12">
                {/* <div className="member_title">
                    <h2>Join Membership</h2> */}
                    {/* <h4>Dont have an account? <Link to="/loginFreeAssesment">Free Signup</Link></h4> */}
                    {/* <h4>Don't have an account? <Link to="/loginFreeAssesment">Signup</Link></h4>
                </div> */}
                <div className="col-12 text-end">
                    {/* <div className="member_title">
                <h4><Link to="/loginFreeAssesment">Login With Free Assesment</Link></h4>
                </div> */}
                </div>
                <div className="login_cont">
                    <div className="info_cont">
                        <h4>Forgot Password</h4>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3 info_field">
                                            <label for="exampleFormControlInput1" className="form-label">Email</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1"
                                                placeholder="Enter email"
                                                {...register("email",
                                                    {
                                                        required: true,
                                                        // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                    })}
                                            />
                                            {errors.email && errors.email.type === "required" && <span className="validation-error">This is required</span>}
                                            {errors.email && errors.email.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                            <div className="work_icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                        stroke="#2D2926"
                                                        stroke-width="1.42857"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-3">
                                        <div className="mb-3 info_field">
                                            <label for="exampleFormControlInput1" className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Password"
                                                {...register('password', {
                                                    required: true,
                                                    pattern: {
                                                        value: /^.{4,}$/, // At least 4 characters
                                                        message: "Password must be at least 4 characters long."
                                                    }
                                                })}
                                            />
                                            {errors.password && errors.password.type === "required" && (
                                                <span className="validation-error">This is required</span>
                                            )}
                                            {errors.password && errors.password.type === "pattern" && (
                                                <span className="validation-error">{errors.password.message}</span>
                                            )}

                                            <div className="work_icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                        stroke="#2D2926"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>} */}
                                </div>
                                <div className="next_btn member_sub_btn">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;

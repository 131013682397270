export const FreevalidateStep = (step, data) => {
    const validationErrors = {};
    const namePattern = /^[a-zA-Z]+$/;
    const phonePattern = /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/;
    // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    switch (step) {
        case 1:
            // First name validation
            if (!data.first_name) {
                validationErrors.first_name = 'Enter your First Name.';
               
            } 
            else if (!namePattern.test(data.first_name)) {
                validationErrors.first_name = 'First name can only contain letters.';
          
            }
          
            // Last name validation
            if (!data.last_name) {
                validationErrors.last_name = 'Enter your Last Name.';
            } else if (!namePattern.test(data.last_name)) {
                validationErrors.last_name = 'Last name can only contain letters.';
            }
            if (data.email && !emailPattern.test(data.email)) validationErrors.email = 'Enter a valid Email.';

            // Other validations
            if (!data.grade) validationErrors.grade = 'Select an option.';
            // if (!data.email) validationErrors.email = 'Enter your Email.';

            // if (!data.cnf_email) validationErrors.cnf_email = 'Enter your Confirm Email.';
            // else if (data.cnf_email !== data.email) validationErrors.cnf_email = 'Email and confirm email do not match.';

            // if (!data.mobile) validationErrors.mobile = 'Enter your Phone Number.';
            // if (!data.gender) validationErrors.gender = 'Select an option.';
            if (!data.mother_mobilenumber) validationErrors.mother_mobilenumber = 'Enter your Main Contact.';
            else if (!phonePattern.test(data.mother_mobilenumber)) {
                validationErrors.mother_mobilenumber = "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX).";
              }
              if (data.father_mobilenumber && !phonePattern.test(data.father_mobilenumber)) {
                validationErrors.father_mobilenumber = "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX).";
              }
            // if (!data.father_mobilenumber) validationErrors.father_mobilenumber = 'Enter your Another Contact .';
            // if (!data.city) validationErrors.city = 'Enter your City.';
            // if (!data.postcode) validationErrors.postcode = 'Enter your Postcode.';
            
            if (!data.p_email) validationErrors.p_email = 'Enter your Parent\'s Email.';
            else if (!emailPattern.test(data.p_email)) validationErrors.p_email = 'Enter a valid Parent\'s Email.';
            // if (!data.cnf_p_email) validationErrors.cnf_p_email = 'Enter your Confirm Parent\'s Email.';
            // else if (data.cnf_p_email !== data.p_email) validationErrors.cnf_p_email = 'Parent\'s email and confirm email do not match.';
            
            // if (!data.sibling) validationErrors.sibling = 'Enter your Sibling.';
            // if (!data.sibling_grade) validationErrors.sibling_grade = 'Select an option.';
            // if (!data.course_type_id) validationErrors.course_type_id = 'Select an option.';
            // if (!data.term_id) validationErrors.term_id = 'Select an option.';
            if (!data.state) validationErrors.state = 'Select an option.';
            // if (!data.session) validationErrors.session = 'Select an option.';
            if (!data.p_first_name) validationErrors.p_first_name = 'Enter your Parent\'s First Name.';
            else if (!namePattern.test(data.p_first_name)) {
                validationErrors.p_first_name = 'Parent\'s First can only contain letters.';    
            }
            if (!data.p_last_name) validationErrors.p_last_name = 'Enter your Parent\'s Last Name.';
            // if (!data.date_of_birth) validationErrors.date_of_birth = 'Enter your DOB.';
            // if (!data.school) validationErrors.school = 'Enter your School Name.';
            break;

        case 2:
            // Email (userId) validation
            if (!data.email) validationErrors.email = 'Enter your UserId(Email).';
            else if (!emailPattern.test(data.email)) validationErrors.email = 'Please enter a valid Email Address.';

            // Password validation
            if (!data.password) validationErrors.password = 'Password is required.';
            else if (data.password.length < 6) validationErrors.password = 'Password must be at least 6 characters long.';
            break;

        default:
            break;
    }

    return validationErrors;
};
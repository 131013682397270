import React, { useState, useEffect } from 'react'
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet, Link, useLocation } from "react-router-dom";
import banner from "../../assets/Images/childrenBanner.png.png"
export default function Layout({grandName}) {

    const [toggle, setToggle] = useState(false);
    const [heading, setHeading] = useState('');
    const location = useLocation();

    const toggleClass = () => {
        setToggle(!toggle);
    };

    // get path name 
    useEffect(() => {
        const currentPath = location.pathname.replace('/', ' ').replace('-', ' ');
        setHeading(currentPath);
    }, [location]);

    const token = localStorage.getItem("Token")
    return (
        <body className={`sb-nav-fixed ${toggle ? 'sb-sidenav-toggled' : ''}`}>
            <Header toggleClass={toggleClass} />
            <div id="layoutSidenav">
                <Sidebar toggleClass={toggleClass} grandName={grandName} />
                <div id="layoutSidenav_content" className="cart_sec">
                    <main>
                        <div className="container-fluid px-md-4">
                            {/* <div className="dashboard-header-sec">
                                <div className='fixed-sec'>
                                    <img src={banner} alt="banner.png" />
                                    <div className="header-content"> */}
                                        {/* <h1 className="">Enjoy our offline classes </h1> */}
                                        {/* <ol className="breadcrumb">
                                            {!localStorage.getItem("Token") ? '' : <li className="breadcrumb-item active"><Link to="/dashboard">Dashboard</Link></li>}
                                            <li class="asse_test">/ {heading?.toUpperCase()} </li>
                                        </ol>
                                    </div>

                                </div>
                            </div> */}
                            <Outlet />
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </body>
    )
}
